import {redirect} from "react-router-dom";

/**
 * return true if game is over
 * @returns {boolean}
 */
const checkOpeStop = () => {

    if( ! ("REACT_APP_DATE_OPE_STOP" in process.env) )
        return false

    //let start_date_string = "05/06/2020".split("/");
    let stop_date_string = process.env.REACT_APP_DATE_OPE_STOP.split("/")
    let stop_date = new Date(stop_date_string[2],stop_date_string[1]-1,stop_date_string[0])
    let now_date = new Date()

    return (now_date >= stop_date)
}

/**
 * Return true if game is started
 * @returns {boolean}
 */
const checkOpeStart = () => {

    if( ! ("REACT_APP_DATE_OPE_START" in process.env) )
        return true

    //let start_date_string = "05/06/2020".split("/");
    let start_date_string = process.env.REACT_APP_DATE_OPE_START.split("/")
    let start_date = new Date(start_date_string[2],start_date_string[1]-1,start_date_string[0])
    let now_date = new Date()

    return (now_date >= start_date)
}

/**
 * redirect user to waiting page if ope is not started yet
 * @returns {Response|null}
 */
export const isOpeStartRedirect = () => {

    if(!checkOpeStart())
        return redirect("/attente")

    return null
}

/**
 * redirect user to ending page if ope is finished
 * @returns {Response|null}
 */
export const isOpeStopRedirect = () => {
    if(checkOpeStop())
        return redirect("/operation-terminee")

    return null
}

/**
 * redirect user to a default page if operation is in progress
 * @returns {Response|null}
 */
export const isOpeInProgressRedirect = () => {

    if( checkOpeStart() && !checkOpeStop() )
        return redirect("/")

    return null
}